import type { LegacyRef } from "react";
import { useState } from "react";
import classNames from "classnames";
import fuzzysort from "fuzzysort";
import { groupBy, map, trim } from "lodash";
import { t } from "@/i18n-js/instance";
import { useFocusOnLoad } from "@/react/hooks/useFocusOnLoad";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { DropdownMenu_DEPRECATED } from "../DropdownMenu";
import { NoResultsListItem } from "./NoResultsListItem";
import { SpaceList } from "./SpaceList";

export interface SpacesDropdown {
  open: boolean;
  handleClose: () => void;
  onChange: (id: number) => void;
  spaces: Space[];
  shouldDisplayHomepage: boolean;
  className?: string;
  placeholder?: string;
}

export const SpacesDropdown = ({
  open,
  handleClose,
  onChange,
  spaces,
  shouldDisplayHomepage,
  className,
  placeholder = t("search_space_placeholder"),
}: SpacesDropdown) => {
  const inputRef: LegacyRef<HTMLInputElement> = useFocusOnLoad([open]);
  const [searchQuery, setSearchQuery] = useState("");
  const { isV3Enabled } = useIsV3();
  const onSearch = (event: any) => {
    setSearchQuery(trim(event.target.value));
  };

  const handleSpace = (_: any, id: number) => {
    onChange(id);
    handleClose();
  };

  let filterSpaces = spaces;
  if (searchQuery) {
    filterSpaces = fuzzysort
      .go(searchQuery, spaces, { keys: ["name", "space_group_name"] })
      .map(res => res.obj);
  }
  const groupedSpaces = groupBy(filterSpaces, "space_group_name");

  return (
    <DropdownMenu_DEPRECATED
      open={open}
      handleClose={handleClose}
      className={classNames(
        "react-space-selector-dropdown__wrapper",
        className,
      )}
    >
      <div className="p-4 pb-0">
        <input
          id="space-search"
          type="text"
          aria-label={t("search_space")}
          ref={inputRef}
          className="react-space-selector-dropdown__input placeholder:text-light placeholder:opacity-100"
          placeholder={placeholder}
          onChange={onSearch}
        />
      </div>
      {shouldDisplayHomepage && (
        <ul className="react-space-selector-dropdown__list mt-4">
          <li className="!p-0">
            <button
              type="button"
              className="flex w-full items-center gap-2 px-5 py-2"
              onClick={event => handleSpace(event, 0)}
            >
              {isV3Enabled ? (
                <Icon type="20-feed-v3" size={20} />
              ) : (
                <Icon type="home-v2" size={16} />
              )}
              <span className="react-space-selector-dropdown__name">
                {isV3Enabled ? t("feed") : t("home_page.title")}
              </span>
            </button>
          </li>
        </ul>
      )}
      {map(Object.entries(groupedSpaces), ([space_group_name, _spaces]) => (
        <div key={space_group_name}>
          <span className="react-space-selector-dropdown__category">
            {space_group_name}
          </span>
          <SpaceList spaces={_spaces} handleSpace={handleSpace} />
        </div>
      ))}
      {filterSpaces.length === 0 && <NoResultsListItem />}
    </DropdownMenu_DEPRECATED>
  );
};
