/*
  Ids used on this file:
  /app/assets/images/sprite/icons.svg
*/
export const ICONS_2 = [
  "16-edit",
  "16-lock-unlocked",
  "16-sign-out",
  "16-customize",
  "16-info",
  "16-copy",
  "16-arrow-top-right",
  "16-arrow-back",
  "16-file-image",
  "20-character-bold",
  "20-character-h2",
  "20-character-h3",
  "20-character-italic",
  "20-character-underline",
  "20-character-strike",
  "20-character-link",
  "16-video-block",
  "16-text",
  "16-instructor",
  "16-call-to-action",
  "20-info",
  "20-course-video",
  "20-course-audio",
  "16-arrow-right",
  "16-arrow-left",
  "16-lock",
  "16-lock",
  "16-eye-hidden",
  "16-eye-visible",
  "16-messages",
  "16-enable-DM",
  "16-disable-DM",
  "16-unsubscribe",
  "20-checkmark-circle",
  "20-checkmark-circle-fill",
  "20-checkmark-circle-fill-thin",
  "20-checkmark-blue",
  "16-publish",
  "20-members",
  "16-book",
  "16-close",
  "20-close-circle",
  "raise-hand",
  "raise-hand-filled",
  "weak-signal",
  "36-course-file",
  "36-course-file-audio",
  "36-course-file-video",
  "36-course-file-pdf",
  "12-arrow-up",
  "12-arrow-down",
  "20-add-sm",
  "coupon-tag",
  "chat-thread",
  "16-exit",
  "20-chevron-down-sm",
  "12-lightning",
  "12-lightning-outline",
  "20-menu-dots-horizontal",
  "16-workflow-user",
  "16-workflow-tag",
  "16-workflow-badge",
  "16-workflow-post",
  "16-workflow-course",
  "16-workflow-space",
  "16-workflow-event",
  "16-workflow-chat",
  "16-workflow-unsubscribe",
  "16-workflow-group-chat",
  "16-workflow-paywall",
  "16-workflow-clock",
  "16-workflow-comment",
  "16-workflow-like",
  "16-workflow-notification",
  "16-workflow-profile-field",
  "16-workflow-email",
  "16-workflow-audience",
  "16-workflow-user-remove",
  "16-workflow-time-delay",
  "24-workflow-user",
  "24-workflow-tag",
  "24-workflow-badge",
  "24-workflow-post",
  "24-workflow-course",
  "24-workflow-space",
  "24-workflow-event",
  "24-workflow-chat",
  "24-workflow-unsubscribe",
  "24-workflow-group-chat",
  "24-workflow-paywall",
  "24-workflow-clock",
  "24-workflow-comment",
  "24-workflow-like",
  "24-workflow-notification",
  "24-workflow-profile-field",
  "24-workflow-email",
  "24-workflow-audience",
  "24-workflow-user-remove",
  "24-workflow-time-delay",
  "20-time-delay",
  "20-expand",
  "40-arrow-down",
  "32-line-vertical",
  "16-space-directory",
  "16-space-directory-remove",
  "16-folder-remove",
  "16-calendar-add",
  "16-calendar-join-date",
  "16-comment",
  "16-create-post",
  "16-like",
  "16-chat",
  "16-remove-user",
  "16-notifications",
  "16-email",
  "16-tag",
  "16-tags",
  "16-remove-tags",
  "16-add-badge",
  "16-remove-badge",
  "16-input-select",
  "16-graduate",
  "16-paywall",
  "16-webhook",
  "16-remove-paywall",
  "16-refund-payment",
  "16-wait",
  "16-chat-threads",
  "32-chat-threads",
  "16-members",
  "16-arrow",
  "20-new-tab-link",
  "12-question-mark",
  "20-chat-threads",
  "20-lightning",
  "20-rocket",
  "20-clock",
  "20-checkmark-ring-transparent",
  "host",
  "moderator",
  "20-close-md",
  "cohost",
  "16-keyboard-shortcuts",
  "16-clock",
  "16-notifications",
  "16-search",
  "16-menu-dots-horizontal",
  "16-add-new",
  "announcements",
  "16-external-link",
  "share-support-widget",
  "16-mark-as-read",
  "16-go-live-indicator",
  "24-live-waveform",
  "16-workflow-user-remove-outline",
  "20-add-lg",
  "20-back-to-community",
  "20-members",
  "20-content",
  "20-workflows",
  "20-payments",
  "20-live-stream",
  "20-moderation",
  "20-analytics",
  "20-paywalls-affiliates",
  "16-paywalls-affiliates",
  "20-plans",
  "20-nav-settings",
  "20-fullscreen",
  "20-fullscreen-exit",
  "20-thumbnail",
  "20-cover-image",
  "20-add-circle",
  "20-attach",
  "20-file-video",
  "20-file-image",
  "20-emoji",
  "20-video",
  "20-gif",
  "20-ai",
  "16-smile",
  "16-sad",
  "16-neutral",
  "20-arrow-left",
  "16-transcript",
  "16-go-live",
  "20-variables",
  "16-paywalls",
  "16-plans",
  "16-share",
  "16-chat-threads-header",
  "support-widget-calendar",
  "20-reply-to-thread",
  "16-open-in-space",
  "16-open-threads",
  "16-refresh",
  "20-chevron-down",
  "20-chevron-up",
  "20-folder-open",
  "20-manual-invite",
] as const;
